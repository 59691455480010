import React, { useEffect, useRef, useState } from 'react';
import { CardContainer } from '../global/containers';
import { PieChartItem } from '../global/charts';
import { getElectricVehicleOverview } from '../../services/electricVehicle';
import enzi_theme from '../../theme/theme';

export default function EVStatus() {
	const cardContainerRef = useRef();
	const [pieData, setPieData] = useState({});
	const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

	useEffect(() => {
		if (cardContainerRef !== null && cardContainerRef.current !== null) {
			const containerWidth = cardContainerRef.current.getContainerWidth();
			if (!isNaN(containerWidth)) {
				setCardContainerWidth(containerWidth * 0.8);
			}
		}
	}, [cardContainerRef]);

	useEffect(() => {
		getElectricVehicleOverview()
			.then((res) => {
				setPieData(res);
			})
			.catch((err) => {
				setPieData({});
			});
	}, []);

	return (
		<CardContainer
			ref={cardContainerRef}
			title="EV Status"
			width={{ xs: '100%', lg: '40%' }}
			type="actionable"
			actionableText="See more"
			childrenContainerWidth={'95%'}
		>
			<PieChartItem
				height={300}
				width={cardContainerWidth}
				dataSeries={[
					{
						id: 0,
						value: pieData?.Moving,
						label: 'Moving',
						valueFormatter: (value) => `${value}% vehicles`,
					},
					{
						id: 1,
						value: pieData?.Idling,
						label: 'Idling',
						valueFormatter: (value) => `${value} vehicles`,
						color: enzi_theme.palette.warning.light,
					},
					{
						id: 2,
						value: pieData?.Off,
						label: 'Off',
						valueFormatter: (value) => `${value} vehicles`,
					},
				]}
			/>
		</CardContainer>
	);
}
