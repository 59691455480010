import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CardContainer } from '../global/containers';
import { BarChartItem } from '../global/charts';
import { revenuePerformanceMetrics } from '../../services/metricsService';
import { formatCurrency, isNullUndefined } from '../../util/util';
import enzi_theme from '../../theme/theme';

export default function RevenuePerformanceCard() {
	const cardContainerRef = useRef();
	const [revenue, setRevenue] = useState();
	const [cardContainerWidth, setCardContainerWidth] = useState(undefined);

	const [isLoading, setIsLoading] = useState(false);

	const setChartWidth = useCallback(
		function () {
			if (cardContainerRef !== null && cardContainerRef.current !== null) {
				const containerWidth = cardContainerRef.current.getContainerWidth();

				if (!isNaN(containerWidth)) {
					setCardContainerWidth(containerWidth * 0.85);
				}
			}
		},
		[cardContainerRef]
	);

	useEffect(() => {
		setChartWidth();
	}, [setChartWidth]);

	useEffect(() => {
		setIsLoading(true);
		revenuePerformanceMetrics()
			.then((res) => {
				setRevenue(res);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	return (
		<CardContainer
			ref={cardContainerRef}
			title="Revenue Performance"
			width={{ xs: '100%', lg: '66%' }}
			// height={"50vh"}
			childrenContainerWidth={'97.5%'}
		>
			<BarChartItem
				height={300}
				isLoading={isLoading}
				width={cardContainerWidth}
				dataLabels={['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4']}
				dataSeries={[
					{
						data: isNullUndefined(revenue) ? [] : revenue,
						color: enzi_theme.palette.secondary.main,
						label: '2025',
						valueFormatter: (value) => {
							return `${formatCurrency(value)} Ksh`;
						},
					},
				]}
				chartLabel="Revenue"
			/>
		</CardContainer>
	);
}
