import { useEffect, useState } from 'react';
import { CardContainer } from '../global/containers';
import { CustomDataGrid } from '../global/customTable';
import { getStaleTelemetry } from '../../services/metricsService';
import { formatShortDateTime } from '../../util/util';

const StaleTelemetryCard = () => {
	const [dataRows, setDataRows] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		getStaleTelemetry()
			.then((res) => {
				setDataRows(res);
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	const staleTelemetryColumns = [
		{
			field: 'name',
			headerName: 'Name',
			minWidth: 150,
			flex: 0.75,
		},
		{
			field: 'telemetry_date',
			headerName: 'Telemetry Date Time',
			minWidth: 200,
			flex: 0.75,
			valueFormatter: (value) => `${formatShortDateTime(value)}`,
		},
		{
			field: 'state_name',
			headerName: 'Status',
			minWidth: 25,
			flex: 0.75,
		},
		{
			field: 'charge_pct',
			headerName: 'Charge %',
			minWidth: 25,
			flex: 0.75,
		},
		{
			field: 'lat',
			headerName: 'Latitude',
			minWidth: 75,
			flex: 0.75,
		},
		{
			field: 'lon',
			headerName: 'Longitude',
			minWidth: 75,
			flex: 0.75,
		},
	];

	return (
		<CardContainer
			title="Stale Telemetry (over 10 mins old)"
			width={{ xs: '100%', lg: '50%' }}
			// height={"50vh"}
			childrenContainerWidth={'100%'}
			childrenContainerPadding={0}
			extraSX={{
				justifyContent: 'center',
			}}
		>
			<CustomDataGrid
				columns={staleTelemetryColumns}
				rows={dataRows}
				isLoading={isLoading}
				width="100%"
				height="100%"
			/>
		</CardContainer>
	);
};

export default StaleTelemetryCard;
